.striked-price {
    position: relative;
    font-size: 1.5em;
    text-decoration: none; /* Remove default strikethrough */
    margin-right: 10px;
    transition: opacity 0.3s ease;
  }
  
.striked-price::after {
  content: '';
  position: absolute;
  left: -55%;
  right: 20%;
  height: 18%;
  width: 160%;
  border-radius: 15px;
  background: url('../../assets/images/pen_brush_yellow.png') no-repeat ;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0.8
}

.box-shadowed {
  box-shadow:  '3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)';
  border-radius: '20px'
}

.printable-content {
  margin: 0;
  padding: 0;
  visibility: hidden;
  position: absolute;
  left: -10000px;
  top: -10000px;

}
@media print {
  body {
    margin: 0;
  }
  .printable-content {
    margin: 0;
    padding: 0;
    visibility: visible;
    position: static;
  }
}