.styled-icon {
  color: #616161;
  font-size: 1.9rem !important;
}
.rbc-addons-dnd-drag-preview {
  z-index: 998 !important;
}
.rbc-events-container {
  pointer-events: none;
}
.slotDefault {
  cursor: progress !important;
}
.rbc-time-gutter {
  .rbc-timeslot-group {
    .rbc-time-slot {
      flex-basis: 0px;
    }
  }
}

.rbc-time-content {
  border-top: 1px solid #AAAAAA;
}
.my-calendar {
  min-height: 800px;
  @media (max-width: 900px) {
    min-height: 1000px;
  }
  touch-action: none; 
  * {
    touch-action: none;
  }
} 
 .scrollable-container {
  overflow: auto;
 }
 .rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: auto;
}
.rbc-allday-cell {
  min-height: 44px;
}
/* Réactiver les actions tactiles uniquement pour les éléments avec Hammer.js */

@media (max-width: 900px) {
  .rbc-time-content {
    overflow: hidden;
    touch-action: pan-x pan-y; 
    * {
      touch-action: pan-x pan-y; 
    }
  }
  .rbc-time-view-resources .rbc-time-header-cell-single-day {
      display: flex;
  }
}
/* Cacher uniquement le div de redimensionnement du start */
.rbc-addons-dnd-resize-ns-anchor:first-child {
  display: none;
}
/* Ajouter une marge de 10px correspondant à la hauteur par défaut du div de redimensionnement précédemment caché */
.rbc-addons-dnd-resize-ns-anchor:last-child {
  margin-top: 10px;
}

/* Style de la barre de défilement pour les navigateurs basés sur WebKit (Chrome, Safari) */
.scrollable-container::-webkit-scrollbar {
  width: 14px !important;
  height: 14px !important;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 14px !important;
  height: 14px !important;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
  height: 14px !important;
  /* transition: "background 0.3s, transform 0.3s"; */
}

.rbc-time-content::-webkit-scrollbar-thumb:hover {
  background: #a8be61;
  cursor: pointer !important; /* Affiche un curseur de main au survol */
  transform: scale(1.1);
}

/* Style de la barre de défilement pour Firefox */
.scrollable-container {
  /* touch-action: none !important; */
  scrollbar-width: thin;
  scrollbar-color: #a8be61 #f1f1f1;
}

/* Curseur de main au survol pour Firefox */
.scrollable-container::-moz-scrollbar-thumb:hover {
  cursor: pointer !important;
}


.styled-subtitle {
  font-family: "Caveat";
  font-size: "1.3rem";
}

.overbooked-button:hover {
  background-color: #f27573 !important; /* Couleur de fond au survol */
}

.pink-button:hover {
  background-color: #df8eab !important; /* Couleur de fond au survol */
}

.styled-white-alert {
  border-radius: 20px !important;
  border-bottom: 3px solid #ffffff;
}

.styled-alert {
  border-radius: 20px !important;
  border-bottom: 3px solid #bcd46c;
  border-right: 40px solid transparent;
}

.styled-info-alert {
  border-radius: 20px !important;
  border-bottom: 3px solid #0288d1;
  border-right: 40px solid transparent;
}

.styled-warning-alert {
  border-radius: 20px !important;
  border-bottom: 3px solid #ffd35c;
  border-right: 40px solid transparent;
}

.styled-error-alert {
  border-radius: 20px !important;
  border-bottom: 3px solid #d32f2f;
  border-right: 40px solid transparent;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.p-without-margin > p {
  margin-block : 0;
}

.my-calendar {
    font-family: 'Outfit';
    color:#70757a;
    overflow-y: hidden; /* Facultatif : Vous pouvez ajuster l'overflow vertical selon vos besoins */
}

.my-calendar-event-label {
  font-family: 'Outfit';
  word-wrap: "unset";
  min-height: 25px;
}

.rbc-calendar {
    /* height: 85vh; */
    /* overflow-x: scroll !important; */
}

.rbc-event {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  position: "relative !important";
}

.rbc-header {
  height: 100% !important;
  z-index: 10 ! important;
  height: fit-content;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: end;
  justify-content: center;
}

.rbc-time-slot {
  color: #70757a;
  font-size: 11px;
  border: 0;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 0;
}
.rbc-time-header-gutter {
  z-index: 100 !important;
}

.rbc-time-view {
  border: 0;
}

.rbc-button-link {
  font-weight: bold;
}



.rbc-current-time-indicator {
  /* Styles personnalisés pour la ligne du moment actuel */
  /* Par exemple, changer la couleur de la ligne en vert */
  background-color: red;
  border-top: rgb(234, 67, 53) solid 2px;
}

/* Style de la Toolbar personnalisée 'MyCalendarToolBar */
.rbc-toolbar {
  color: #3c4043;
  display: flex;
}

/* On masque le label du Calendar car il n'est pas personnalisable,
on gère l'intégralité du contenu de l'event avec notre composant MyCalendarEvent  */
.rbc-event-label {
  display: none;
}

.rbc-addons-dnd-resize-ns-anchor {
  position: absolute;
    bottom: 0;
    width: 100%;
  height: 20px;
  cursor: ns-resize;
}

.rbc-addons-dnd-resizable {
  height: calc(100% - 20px);
  min-height: 20px;
  width: 100%;
}
.rbc-time-header {
  margin-right: 0 !important;
}
/* Classes spécifiques pour les effets de survol des events */

.my-new-event:not(.no-hover-effect):hover {
  border: 2px solid transparent;
  /* Effet lumineux autour de l'élément */
  transition: box-shadow 0.2s ease-in-out;
  background-clip: padding-box;
  box-shadow: 0 0 8px 2px #00bf08;
  z-index: 1;
}
.my-note-event:not(.no-hover-effect):hover {
  border: 2px solid transparent;
  /* Effet lumineux autour de l'élément */
  transition: box-shadow 0.2s ease-in-out;
  background-clip: padding-box;
  box-shadow: 0 0 8px 2px #4285f4;
  z-index: 1;
}
.my-new-event-fire:not(.no-hover-effect):hover {
  border: 2px solid transparent;
  /* Effet lumineux autour de l'élément */
  transition: box-shadow 0.2s ease-in-out;
  background-clip: padding-box;
  box-shadow: 0 0 8px 2px red;
  z-index: 1;
}
.my-accepted-event:not(.no-hover-effect):hover {
  border: 2px solid transparent;
  /* Effet lumineux autour de l'élément */
  transition: box-shadow 0.2s ease-in-out;
  background-clip: padding-box;
  box-shadow: 0 0 8px 2px #f29aba;
  z-index: 1;
}
.my-completed-event:not(.no-hover-effect):hover,
.my-done-event:not(.no-hover-effect):hover {
  border: 2px solid transparent;
  /* Effet lumineux autour de l'élément */
  transition: box-shadow 0.2s ease-in-out;
  background-clip: padding-box;
  box-shadow: 0 0 8px 2px #bcd46c;
  z-index: 1;
}
.my-rejected-event:not(.no-hover-effect):hover {
  border: 2px solid transparent;
  /* Effet lumineux autour de l'élément */
  transition: box-shadow 0.2s ease-in-out;
  background-clip: padding-box;
  box-shadow: 0 0 8px 2px #f29aba;
  z-index: 1;
}
.my-scheduled-event:not(.no-hover-effect):hover {
  border: 2px solid transparent;
  /* Effet lumineux autour de l'élément */
  transition: box-shadow 0.2s ease-in-out;
  background-clip: padding-box;
  box-shadow: 0 0 8px 2px #ffd25c;
  z-index: 1;
}

.rbc-event:focus {
  outline: none;
}
.my-cancelled-event:not(.no-hover-effect):hover {
  border: 2px solid transparent;
  /* Effet lumineux autour de l'élément */
  transition: box-shadow 0.2s ease-in-out;
  background-clip: padding-box;
  box-shadow: 0 0 8px 2px rgb(216, 216, 216);
  z-index: 1;
}
.no-hover-effect {
  outline-color: transparent !important;
  pointer-events: none !important;

  .rbc-event-content {
    outline-color: transparent !important;
    pointer-events: none !important;
  }
}
#folded-corner {
  display: none;
}