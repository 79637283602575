:root {
    --vh: 1vh; /* Ceci sera remplacé dynamiquement en JavaScript */
    --fullHeight: calc(var(--vh) * 100);
}
@font-face {
    font-family: 'CarterOne';
    src: url(./assets/font/CarterOne-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
/* quill */
.hide-toolbar {
    .ql-toolbar {
        display: none;
    }
    .ql-container {
        border-top: 1px solid #ccc !important;
    }
}